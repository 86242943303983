import { render, staticRenderFns } from "./UsecasesIdentityFraudDetection.vue?vue&type=template&id=8dea5892&scoped=true&"
var script = {}
import style0 from "./UsecasesIdentityFraudDetection.vue?vue&type=style&index=0&id=8dea5892&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dea5892",
  null
  
)

export default component.exports